import { environment } from 'src/environments/environment';
import { RuoloEnum } from '../shared/enums/ruolo.enum';

const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  hide?: boolean;
  subs?: IMenuItem[];
  roles?: RuoloEnum[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-pie-chart-3',
    label: 'menu.dashboard',
    to: `${adminRoot}/dashboard`,
    roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario, RuoloEnum.Cassiere]
  },
  {
    icon: 'iconsminds-profile',
    label: 'menu.membri',
    to: `${adminRoot}/membri`,
    roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario],
    subs: [
      {
        icon: 'iconsminds-pie-chart-3',
        label: 'menu.riepilogo',
        to: `${adminRoot}/membri/riepilogo`
      },
      {
        icon: 'simple-icon-people',
        label: 'menu.lista-membri',
        to: `${adminRoot}/membri/lista-membri`,
      },
      {
        icon: 'simple-icon-user-follow',
        label: 'menu.aggiungi-membro',
        to: `${adminRoot}/membri/aggiungi-membro`,
      }
    ],
  },
  {
    icon: 'iconsminds-lantern',
    label: 'menu.cappelle',
    to: `${adminRoot}/cappelle`,
    roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario],
    subs: [
      {
        icon: 'iconsminds-lantern',
        label: 'menu.lista-cappelle',
        to: `${adminRoot}/cappelle/lista-cappelle`,
        roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario]
      },
      {
        icon: 'iconsminds-add',
        label: 'menu.aggiungi-cappella',
        to: `${adminRoot}/cappelle/aggiungi-cappella`,
        roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario]
      }
    ],
  },
  {
    icon: 'iconsminds-notepad',
    label: 'menu.contratti',
    to: `${adminRoot}/contratti`,
    roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario],
    subs: [
      {
        icon: 'iconsminds-notepad',
        label: 'menu.lista-contratti',
        to: `${adminRoot}/contratti/lista-contratti`,
        roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario]
      },
      {
        icon: 'iconsminds-add',
        label: 'menu.aggiungi-contratto',
        to: `${adminRoot}/contratti/aggiungi-contratto`,
        roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Segretario]
      }
    ]
  },
  {
    icon: 'iconsminds-billing',
    label: 'menu.libro-giornale',
    to: `${adminRoot}/libro-giornale`,
    roles: [RuoloEnum.Amministratore, RuoloEnum.Operatore, RuoloEnum.Cassiere],
    subs: [
      {
        icon: 'iconsminds-pie-chart-3',
        label: 'menu.bilancio',
        to: `${adminRoot}/libro-giornale/bilancio`
      },
      {
        icon: 'iconsminds-wallet',
        label: 'menu.lista-movimenti',
        to: `${adminRoot}/libro-giornale/lista-movimenti`,
      },
      {
        icon: 'iconsminds-add',
        label: 'menu.aggiungi-movimento',
        to: `${adminRoot}/libro-giornale/aggiungi-movimento`,
      },
      {
        icon: 'simple-icon-people',
        label: 'menu.stato-confrati',
        to: `${adminRoot}/libro-giornale/stato-membri`,
      },
    ],
  },
  {
    icon: 'iconsminds-office',
    label: 'menu.associazione',
    to: `${adminRoot}/associazione`,
    roles: [RuoloEnum.Amministratore],
    subs: [
      /*
      {
        icon: 'iconsminds-office',
        label: 'menu.associazione',
        to: `${adminRoot}/associazione`,
      },
      {
        icon: 'simple-icon-settings',
        label: 'menu.settings',
        to: `${adminRoot}/associazione/impostazioni`,
      }
      */
    ],
  },
  {
    icon: 'iconsminds-male-2',
    label: 'menu.profilo',
    to: `${adminRoot}/profilo`,
    hide: true,
    subs: [
      {
        icon: 'iconsminds-key',
        label: 'menu.account',
        hide: true,
        to: `${adminRoot}/profilo/account`,
      },
      {
        icon: 'iconsminds-key-lock',
        label: 'menu.change-password',
        hide: true,
        to: `${adminRoot}/profilo/change-password`,
      },
    ],
  },
];
export default data;
